<template>
  <div class="feed-body">
    <div class="feed-contents">
      <div class="d-flex flex-row w-100 justify-between mt-5 mb-3">
        <span class="white--text font-30 app-medium-font">{{
          $t("donate")
        }}</span>
        <v-btn icon @click="goToHistory"
          ><v-icon color="white">mdi-history</v-icon></v-btn
        >
      </div>
      <div class="d-flex flex-row justify-start w-100 mb-5">
        <span class="white--text font-18 app-regular-font">
          Which nonprofit organization would you like to donate to?
        </span>
      </div>
      <div class="mt-4 w-100 mb-5 px-3">
        <v-menu offset-y v-model="searchDropdown">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="searchKey"
              hide-details
              placeholder="Search"
              solo
              flat
              dense
              append-icon="mdi-magnify"
              class="mt-sm-1 mt-md-1 mt-lg-1 mt-xl-1"
              elevation="0"
              v-bind="attrs"
              v-on="on"
              background-color="#D6CDF7"
              autocomplete="off"
              @keyup.enter="onSearch"
            />
          </template>
          <div style="max-height: 60vh">
            <v-list>
              <v-list-item
                v-for="(item, index) in recentSearches"
                :key="index"
                class="list-item"
              >
                <v-list-item-title
                  @click="onSelectKey(item.key)"
                  style="display: flex; justify-content: space-between; align-items: center"
                >
                  {{ item.key }}
                  <v-btn icon @click.stop="onDeleteKey(item)"
                    ><v-icon>mdi-close</v-icon></v-btn
                  >
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="onSearch" class="list-item">
                <v-list-item-title>
                  <v-icon class="mr-2">mdi-magnify</v-icon>Search
                  {{ searchKey ? `for "${searchKey}"` : "" }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </v-menu>
      </div>
      <v-row style="width: 100%">
        <v-col v-for="agency in agencies" :key="agency._id" cols="6">
          <donation-agency-card :agency="agency" />
        </v-col>
      </v-row>
      <v-progress-circular
        indeterminate
        color="amber"
        v-if="loading"
        class="mt-10"
      />
      <div
        v-else-if="agencies.length == 0"
        class="mt-10 d-flex flex-column white--text align-center justify-center white--text"
      >
        <div class="mb-3 mt-15">
          <v-img
            :src="require('@/assets/svg/empty-post.svg')"
            style="width: 60px; height: 50px"
            contain
          />
        </div>
        No agencies match to your causes
      </div>
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import DonationAgencyCard from "./DonationAgencyCard";
export default {
  components: { DonationAgencyCard },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      loadCompleted: -1,
      loadingMore: false,
      searchDropdown: false,
      searchKey: "",
      recentSearches: [],
    };
  },
  methods: {
    ...mapActions({
      fetchAllAgencies: "agency/fetchAllAgencies",
      getSearchContents: "search/getSearchContents",
      getSearchHistory: "search/getSearchHistory",
      deleteKey: "search/deleteKey",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    loadedData() {
      this.loadCompleted++;
    },
    loadMoreAgencies() {
      this.loadingMore = true;
      this.loadMore({ skip: this.agencies.length })
        .then(() => {
          setTimeout(() => {
            this.loadingMore = false;
          }, 2500);
        })
        .catch((error) => {
          this.loadingMore = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    getCompletedInfo(agency) {
      var str = "";
      if (!agency.image) str += "Logo";
      if (!agency.who_we_are)
        str ? (str += ", Who we are") : (str = "Who we are");
      if (!agency.what_we_do)
        str ? (str += ", What we do") : (str = "What we do");
      if (!agency.contact_person || !agency.contact_title)
        str ? (str += ", Contact Info") : (str = "Contact Info");
      if (!agency.phone) str ? (str += ", Phone") : (str = "Phone");
      if (!agency.address) str ? (str += ", Address") : (str = "Address");
      if (!agency.city) str ? (str += ", City") : (str = "City");
      if (!agency.state) str ? (str += ", State") : (str = "State");
      if (!agency.zip) str ? (str += ", Zip") : (str = "Zip");

      return str;
    },
    onSelected(agency) {
      console.log(agency);
    },
    onSearch() {
      this.searchDropdown = false;
      if (this.searchKey != this.$route.query.search) {
        this.$router.replace({ query: { search: this.searchKey } });
      }
    },
    onSelectKey(key) {
      this.searchKey = key;
      this.searchDropdown = false;
      this.onSearch();
    },
    onDeleteKey(key) {
      this.deleteKey({ _id: key._id })
        .then((res) => {
          this.recentSearches = this.recentSearches.filter(
            (r) => r._id != res._id
          );
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    onLoadAgencies() {
      this.loading = true;
      this.loadCompleted = 0;
      this.fetchAllAgencies({ search: this.$route.query.search })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    goToHistory() {
      this.$router.push({ name: "donation-history" });
    },
  },
  computed: {
    ...mapGetters("agency", {
      agencies: "getAllAgencies",
    }),
  },
  watch: {
    searchDropdown(newValue) {
      if (newValue) {
        this.getSearchHistory({ type: "Agency" })
          .then((res) => {
            this.recentSearches = res;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    "$route.query.search": function() {
      this.onLoadAgencies();
    },
  },
  mounted() {
    this.onLoadAgencies();
    this.searchKey = this.$route.query.search;
  },
};
</script>
<style scoped>
.feed-body {
  width: 100%;
  min-width: 200px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feed-contents {
  width: 100%;
  max-width: 650px;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
</style>
